import React, { FC, useCallback } from "react";
import { kebabCase } from "lodash";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export interface LaunchLinkProps {
  jurisdiction?: {
    name: string;
    stateAbbr: string;
    jurisdictionType: string;
  } | null;
}

const StyledButton = styled(Button)`
  background-color: #39adc1;
  border-color: #39adc1;
  &:hover {
    background-color: #2d92a3;
    border-color: #2d92a3;
  }
`;

const LaunchLink: FC<LaunchLinkProps> = ({
  jurisdiction,
}) => {
  const navigate = useNavigate();
  const jurType = kebabCase(jurisdiction?.jurisdictionType.toLowerCase());
  const jurName = jurisdiction?.name.toLowerCase().replace(/ /g, "-");
  const stateAbbr = jurisdiction?.stateAbbr.toLowerCase();
  const path = `/${stateAbbr}/${jurType}/${jurName}`;
  const handleLaunch = useCallback(() => navigate(path), [path, navigate]);

  if (!jurisdiction) return null;

  return (
    <StyledButton
      size="lg"
      onClick={handleLaunch}
      className="rounded-0 px-5 mt-3 mt-lg-0"
    >
      Launch
    </StyledButton>
  );
};

export default LaunchLink;
