import React, { FC, useCallback, useMemo } from "react";
import Select from "react-select";
import { Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import useAllStates from "hooks/useAlLStates";

export interface AlLStatesSelectProps {
  stateAbbr?: string;
}

const AllStatesSelect: FC = () => {
  const { stateAbbr } = useParams();
  const navigate = useNavigate();
  const { data, called, loading } = useAllStates();

  const handleSelect = useCallback(
    (selection: { value: string } | null) => {
      if (!selection?.value) return;
      navigate(`/${selection.value.toLowerCase()}`);
    },
    [navigate],
  );

  const stateOptions = useMemo(() => {
    if (!data) return [];
    // Because we do not need Territories...
    const statesOnly = data.states.filter(
      (state) =>
        state.collectsBpp && state.jurisdictionType.toLowerCase() === "state",
    );

    return statesOnly.map((state) => ({
      value: state.abbr,
      label: state.name,
    }));
  }, [data]);

  if (!called || loading) return <Spinner animation="border" />;
  const currentSelection = stateOptions.find(
    (state) => state.value.toLowerCase() === stateAbbr,
  );

  return (
    <Select
      value={currentSelection}
      onChange={handleSelect}
      options={stateOptions}
    />
  );
};

export default AllStatesSelect;
