import React, { FC, useCallback, useMemo, useState } from "react";
import { states } from "@taxscribe/utils";
import { useParams } from "react-router-dom";
import Select, { StylesConfig } from "react-select";
import { Form, Col, Spinner } from "react-bootstrap";

import LaunchLink from "../LaunchLink";
import checkEfileDeep from "utils/checkEfileDeep";
import sortByHasEfile from "utils/sortByHasEfile";
import useCurrentState from "../hooks/useCurrentState";
import { Jurisdiction } from "../../../types/jurisdiction";
import useJurisdictionType from "../hooks/useJursdictionType";
import useJurisdictionSelectOptions from "../hooks/useJurisdictionSelectOptions";
import styled from "styled-components";

type JurisdictionOption = {
  value: Jurisdiction;
  label: string;
} | null;

const useOptionStyles = (jurisdictions: Jurisdiction[] | null) => {
  return useMemo(() => {
    const optionStyles: StylesConfig = {
      option: (styles, { data }) => {
        const jurData = data as JurisdictionOption | null;
        const hasEfile = checkEfileDeep(jurisdictions, jurData);

        if (hasEfile) {
          return {
            ...styles,
            fontWeight: "bold",
          };
        }

        return styles;
      },
    };

    return optionStyles;
  }, [jurisdictions]);
};

const isJurisdictionOption = (opt: any): opt is JurisdictionOption => {
  if (opt?.label && opt?.value) return true;
  return false;
};

const LaunchLinkWrapper = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: column;
  height: 100%;
`;

export interface CountyLandingPageProps {
  label?: string;
  alternateJurisdictionType?: string;
}

const CountyLandingPage: FC<CountyLandingPageProps> = ({
  label = "Select Your County",
  alternateJurisdictionType = "county",
}) => {
  const [county, setCounty] = useState<JurisdictionOption>();
  const { stateAbbr } = useParams();
  const { data, loading } = useCurrentState();
  const jurisdictions = data?.state?.jurisdictions || null;
  const counties = useJurisdictionType({
    jurisdictions,
    jurType: alternateJurisdictionType,
  });
  const optionStyles = useOptionStyles(counties);

  const memoizedSorter = useMemo(() => {
    return sortByHasEfile(jurisdictions);
  }, [jurisdictions]);

  const countyOptions = useJurisdictionSelectOptions({
    jurisdictions: counties,
    sort: memoizedSorter,
  });

  const upCaseStateAbbr = stateAbbr?.toUpperCase();

  const selectCounty = useCallback(
    (county: unknown) => {
      if (isJurisdictionOption(county)) {
        setCounty(county);
        return;
      }
      setCounty(null);
    },
    [setCounty],
  );

  if (loading) return <Spinner animation="border" />;

  if (!Object.keys(states).includes(upCaseStateAbbr || "")) {
    return <div>Invalid state abbreviation</div>;
  }

  return (
    <>
      <Col md={4}>
        <Form.Label className="fs-4">{label}</Form.Label>
        <Select
          options={countyOptions}
          onChange={selectCounty}
          styles={optionStyles}
        />
      </Col>
      <Col md={2}>
        <LaunchLinkWrapper>
          <LaunchLink jurisdiction={county?.value} />
        </LaunchLinkWrapper>
      </Col>
    </>
  );
};

export default CountyLandingPage;
