import { useMemo } from "react";
import { Jurisdiction } from "../../../types/jurisdiction";

interface UseJurisdictionTypeArgs {
  jurType: string;
  jurisdictions: Jurisdiction[] | null;
}

const useJurisdictionType = ({
  jurType,
  jurisdictions,
}: UseJurisdictionTypeArgs) => {
  return useMemo(() => {
    return (
      jurisdictions?.filter((jur) => {
        return jur.jurisdictionType.toLowerCase() === jurType.toLowerCase();
      }) || []
    );
  }, [jurisdictions, jurType]);
};

export default useJurisdictionType;
