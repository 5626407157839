/* eslint-disable max-len */
import React, { FC } from "react";
import Footer from "components/Footer";
import Navigation from "components/Navigation";
import { useJurisdictionContext } from "@taxscribe/ui";
import config from "config/hostnames";
import styled from "styled-components";
import { capitalize } from "lodash";
import useAllStates, { GetAllStatesResults } from "hooks/useAlLStates";
import { useParams } from "react-router-dom";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: auto;
  margin-top: auto;
`;

const LeadText = styled.p`
  padding-top: 1rem;
  font-size: 1.5rem;
  text-align: center;
`;

const minWidth = "500px";
const MainContent = styled.div`
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  max-width: ${minWidth};
  p {
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  min-width: ${minWidth};

  justify-content: center;
  margin-top: 3rem;
  gap: 1rem;
  & > .btn {
    border: 0px;
    color: #fcfcfc;
    min-width: 150px;
    border-radius: 3px;
    background-color: #39adc1;
  }

  & > .btn-light {
    border: 2px solid #000;
    background-color: #fcfcfc;
    color: #000;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    & > .btn {
      width: 70%;
    }
  }
`;

interface MessageProps {
  jurisdictionName: string;
  jurisdictionType: string;
  currentState?: GetAllStatesResults["states"][number];
}

const CountyLevelMessage: FC<MessageProps> = ({
  currentState,
  jurisdictionName,
  jurisdictionType,
}) => {
  return (
    <>
      <LeadText>
        Taxscribe Business is not available in{" "}
        {capitalize(jurisdictionName || "")} {jurisdictionType} yet.
      </LeadText>
      <MainContent>
        <p>Sign up or sign in to Taxscribe Pro to prepare your filings!</p>
      </MainContent>
    </>
  );
};

const StateLevelMessage: FC<Pick<MessageProps, "currentState">> = ({
  currentState,
}) => {
  return (
    <>
      <LeadText>
        <b>Coming soon:</b> Managed filings for {currentState?.name || ""}!
      </LeadText>
      <MainContent>
        <p>Sign up with Taxscribe Pro to stay up to date!</p>
      </MainContent>
    </>
  );
};

const Message: FC = () => {
  const { stateAbbr } = useParams();
  const { data, loading, called } = useAllStates();
  const { jurisdictionName, jurisdictionType } = useJurisdictionContext();
  const currentState = data?.states?.find(
    (state) => state.abbr.toLowerCase() === stateAbbr,
  );

  if (jurisdictionName && jurisdictionType) {
    return (
      <CountyLevelMessage
        currentState={currentState}
        jurisdictionName={jurisdictionName}
        jurisdictionType={jurisdictionType}
      />
    );
  }

  if (loading || !called) return null;
  return <StateLevelMessage currentState={currentState} />;
};

const NoEfile: FC = () => {
  const signUpLink = `${config.professionalUri}login`;
  const signInLink = `${config.professionalUri}register/new-org`;

  return (
    <div id="wrapper">
      <Navigation redirectOverride="/" />
      <ContentWrapper>
        <Message />
        <ActionButtons>
          <a
            className="btn"
            href={signUpLink}
          >
            SIGN IN
          </a>
          <a
            className="btn btn-light"
            href={signInLink}
          >
            SIGN UP
          </a>
        </ActionButtons>
      </ContentWrapper>
      <Footer />
    </div>
  );
};

export default NoEfile;
