/* eslint-disable max-len */
import React, { FC, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import MILandingPage from "./MILandingPage";
import CountyLandingPage from "./CountyLanding";
import useAllStates from "hooks/useAlLStates";

const stateLandingPageMapping: Record<string, FC> = {
  mi: MILandingPage,
  la: () => (
    <CountyLandingPage
      label="Select Your Parish"
      alternateJurisdictionType="Parish"
    />
  ),
};

const StateLandingPage: FC = () => {
  const navigate = useNavigate();
  const { data, loading, called } = useAllStates();
  const { stateAbbr = "" } = useParams();
  const LandingPage = stateLandingPageMapping[stateAbbr] || CountyLandingPage;
  const selectedState = useMemo(() => {
    if (!data) return null;
    return data.states.find((state) => state.abbr.toLowerCase() === stateAbbr);
  }, [data, stateAbbr]);

  const hasForms = Boolean(selectedState?.formTypes?.length);

  useEffect(() => {
    if (!called || loading) return;
    if (stateAbbr && !hasForms) {
      navigate(`/${stateAbbr}/no-efile`);
    }
  }, [stateAbbr, navigate, LandingPage, hasForms, loading, called]);

  if (!LandingPage) return null;
  return <LandingPage />;
};

export default StateLandingPage;
