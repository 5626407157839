import React, { FC, useCallback, useMemo, useState } from "react";
import Select, { StylesConfig } from "react-select";
import { Col, Form } from "react-bootstrap";

import checkEfileDeep from "utils/checkEfileDeep";
import sortByHasEfile from "utils/sortByHasEfile";
import useCurrentState from "../hooks/useCurrentState";
import { Jurisdiction } from "../../../types/jurisdiction";
import useJurisdictionType from "../hooks/useJursdictionType";
import useChildJurisdictions from "../hooks/useChildJurisdictions";
// eslint-disable-next-line max-len
import useJurisdictionSelectOptions from "../hooks/useJurisdictionSelectOptions";
import LaunchLink from "../LaunchLink";

type JurisdictionOption = {
  value: Jurisdiction;
  label: string;
} | null;

interface JurisdictionDependentComponentProps {
  jurisdiction?: JurisdictionOption | null;
}

interface LUSelectProps extends JurisdictionDependentComponentProps {
  lu: JurisdictionOption | null;
  jurisdictions: Jurisdiction[] | null;
  selectLu: (opt: unknown) => void;
}

const useOptionStyles = (jurisdictions: Jurisdiction[] | null) => {
  return useMemo(() => {
    const optionStyles: StylesConfig = {
      option: (styles, { data }) => {
        const jurData = data as JurisdictionOption | null;
        const hasEfile = checkEfileDeep(jurisdictions, jurData);

        if (hasEfile) {
          return {
            ...styles,
            fontWeight: "bold",
          };
        }

        return styles;
      },
    };

    return optionStyles;
  }, [jurisdictions]);
};

const isJurisdictionOption = (opt: any): opt is JurisdictionOption => {
  if (opt?.label && opt?.value) return true;
  return false;
};

const LUSelect: FC<LUSelectProps> = ({
  lu,
  selectLu,
  jurisdiction,
  jurisdictions,
}) => {
  const lus = useChildJurisdictions({
    jurId: jurisdiction?.value?.id,
    jurisdictions,
  });

  const memoizedSorter = useMemo(() => {
    return sortByHasEfile(jurisdictions);
  }, [jurisdictions]);

  const optionStyles = useOptionStyles(jurisdictions);

  const luOptions = useJurisdictionSelectOptions({
    jurisdictions: lus,
    sort: memoizedSorter,
  });

  if (!jurisdiction) return null;

  return (
    <>
      <Form.Label className="fs-4">Select Your Local Unit</Form.Label>
      <Select
        value={lu}
        options={luOptions}
        onChange={selectLu}
        styles={optionStyles}
      />
    </>
  );
};

const MILandingPage: FC = () => {
  const [county, setCounty] = useState<JurisdictionOption | null>(null);
  const [lu, setLu] = useState<JurisdictionOption | null>(null);

  const { data, loading } = useCurrentState();
  const jurisdictions = data?.state?.jurisdictions || null;
  const memoizedSorter = useMemo(() => {
    return sortByHasEfile(jurisdictions);
  }, [jurisdictions]);

  const counties = useJurisdictionType({
    jurType: "County",
    jurisdictions,
  });

  const countyOptions = useJurisdictionSelectOptions({
    jurisdictions: counties,
    sort: memoizedSorter,
  });

  const optionStyles = useOptionStyles(jurisdictions);

  const selectCounty = useCallback(
    (county: unknown) => {
      if (isJurisdictionOption(county)) {
        setCounty(county);
        setLu(null);
        return;
      }
      setCounty(null);
      setLu(null);
    },
    [setCounty, setLu],
  );

  const selectLu = useCallback(
    (lu: unknown) => {
      if (isJurisdictionOption(lu)) {
        setLu(lu);
        return;
      }

      setLu(null);
    },
    [setLu],
  );

  if (loading) return null;

  return (
    <>
      <Col lg={3}>
        <Form.Label className="fs-4">Select Your County</Form.Label>
        <Select
          options={countyOptions}
          onChange={selectCounty}
          styles={optionStyles}
        />
      </Col>
      <Col lg={3}>
        <LUSelect
          lu={lu}
          selectLu={selectLu}
          jurisdiction={county}
          jurisdictions={jurisdictions}
        />
      </Col>
      <Col className="align-self-end">
        <div>
          <LaunchLink jurisdiction={lu?.value} />
        </div>
      </Col>
    </>
  );
};

export default MILandingPage;
