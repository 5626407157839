import { gql, useQuery } from "@apollo/client";

export const GET_ALL_STATES = gql`
  query getAllStates {
    states {
      abbr
      name
      collectsBpp
      jurisdictionType
      formTypes {
        id
        longName
        shortName
        stateAbbr
        formGroup
        formGroupId
        createdAt
        updatedAt
        constantName
        pluralShortName
      }
    }
  }
`;

export interface GetAllStatesResults {
  states: {
    abbr: string;
    name: string;
    collectsBpp: boolean;
    jurisdictionType: string;
    formTypes: {
      id: number;
      longName: string;
      shortName: string;
      stateAbbr: string;
      formGroup: string;
      formGroupId: number;
      createdAt: string;
      updatedAt: string;
      constantName: string;
      pluralShortName: string;
    }[];
  }[];
}

const useAllStates = () => {
  return useQuery<GetAllStatesResults>(GET_ALL_STATES);
};

export default useAllStates;
