import React from "react";
import Col from "react-bootstrap/Col";
import messageMap, { GenericDefaultMessage } from "./messageMap";
import { useJurisdictionContext } from "@taxscribe/ui";
import { PrivateLogoImage } from "components/AppImages";

const getOrDefault = <T, >(obj: Record<string, T>, key: string) => {
  return obj[key] || obj.DEFAULT;
};

const CountyMessage = () => {
  const { stateAbbr, fullSlug, sponsorsBppListing, name } =
    useJurisdictionContext();

  if (sponsorsBppListing === true) {
    const stateMessages = getOrDefault(messageMap, stateAbbr.toUpperCase());
    const Message =
      getOrDefault(stateMessages, name.toUpperCase()) || GenericDefaultMessage;

    console.log(Message);
    return (
      <>
        <Col
          md={8}
          lg={5}
          className="justify-content-center d-flex flex-column align-items-center mb-3 mb-lg-0"
        >
          <div
            id="login-county"
            className="mt-4 mt-lg-0"
          >
            <PrivateLogoImage
              labelPath={fullSlug}
              width="200"
              height="auto"
            />
            <h2 className="text-center mb-4">Important Message</h2>
            <Message name={name} />
          </div>
        </Col>
        <Col
          lg={2}
          className="d-none d-lg-flex justify-content-center align-items-center"
        >
          <div className="border border-white border-2 h-50"></div>
        </Col>
      </>
    );
  }

  return null;
};

export default CountyMessage;
