import React, { FC } from "react";

export const GenericDefaultMessage: FC<{ name: string }> = ({ name }) => (
  <p className="mx-sm-5 mx-md-2 mx-lg-0">
    TaxScribe is {name}'s web portal for filing business personal property
    extension requests and listings. Online listing forms make the listing
    process easy and allow for multiple revisions or reviews prior to
    submission. Files can be readily attached to listing forms or as
    supplemental documentation. Data validation means fewer errors which
    expedites our review and acceptance. TaxScribe provides real time status,
    includes a dedicated message system and maintains a full history of your
    filing. TaxScribe supports all modern browsers.
  </p>
);

const messageMap: Record<string, Record<string, FC<{ name: string }>>> = {
  NC: {
    "MECKLENBURG COUNTY": ({ name }) => (
      <div>
        <p className="mx-sm-5 mx-md-2 mx-lg-0">
          Taxscribe is Mecklenburg County's web portal for filing business
          personal property extension requests and listings. Online listing
          forms make the listing process easy and allow for multiple revisions
          or reviews prior to submission. Files can be readily attached to
          listing forms or as supplemental documentation. Data validation means
          fewer errors which expedites our review and acceptance. Taxscribe
          provides real time status, includes a dedicated message system and
          maintains a full history of your filing. Taxscribe supports all modern
          browsers.
        </p>
        <p>
          <b>
            Please note any changes to the North Carolina Cost Index and
            Depreciation Schedules may affect your reporting. Please review the
            memorandum published by the NC Department of Revenue, attached to
            the Cost Index each year prior to filing. It can be found{" "}
            <a
              target="_blank"
              className="text-white"
              rel="noopener noreferrer"
              href="https://www.ncdor.gov/news/reports-and-statistics/property-tax-publications/cost-index-and-depreciation-schedules"
            >
              here.
            </a>
          </b>
        </p>
      </div>
    ),
  },
  DEFAULT: {
    DEFAULT: GenericDefaultMessage,
  },
};

export default messageMap;
