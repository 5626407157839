import React, { FC } from "react";

import Image from "./Image";
import { TaxpayerNavBar, useJurisdictionContext } from "@taxscribe/ui";
import styled from "styled-components";

interface NavBannerProps {
  fullSlug: string;
  name: string;
}

const StyledSpan = styled.span`
  @media (max-width: 768px) {
    display: none;
  }
`;

const NavBanner: FC<NavBannerProps> = ({ fullSlug, name }) => {
  return (
    <>
      <Image
        fallbackSrc="/images/seals/taxscribe-bus-logo.png"
        src={`/images/seals/${fullSlug.replace(/ /g, "-")}.png`}
        alt="County Seal"
        height="50"
        loading="lazy"
        className="me-2"
      />{" "}
      <StyledSpan>
        {name} Business Tax Portal
      </StyledSpan>
    </>
  );
};

export interface NavigationProps {
  redirectOverride?: string;
}

const Navigation: FC<NavigationProps> = ({ redirectOverride }) => {
  const { fullSlug, name, webPath } = useJurisdictionContext();
  const logoutPath = `/${webPath}/login`;
  return (
    <TaxpayerNavBar
      logoutPath={logoutPath}
      redirectOverride={redirectOverride}
      banner={
        <NavBanner
          fullSlug={fullSlug}
          name={name}
        />
      }
    />
  );
};

export default Navigation;
